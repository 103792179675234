const model = {
	get isDiscountedRoom() {
		return model.roomType === 'ground';
	},
	dates: {
		get firstDay() {
			return $selected.first().data('date');
		},
		get lastDay() {
			return $selected.last().data('date');
		}
	},
	get arbitraryDiscount() {
		return Math.max(0, Math.min(100, parseInt($('#discount').val() || 0, 10)));
	},
	get isEarlyBird() {
		return differenceWithToday(model.dates.firstDay) >= 30;
	},
	get roomType() {
		const formValue = $('input[name=\'room\']:checked').val();
		if (formValue === 'auto') {
			// If 0, do automatically
			return model.people.full.amount + model.kids.filter(age => age > 3).length > 2 ? 'family' : 'double';
		}
		return formValue;
	},
	get kids() {
		return $('#kids').val().split(' ')
			.filter(a => a !== '')
			.map(a => parseInt(a, 10))
			.sort((a, b) => b - a);
	},
	get people() {
		const adults = parseFloat($('#people').val() || 0);
		let kidsFree = 0;
		let kidsLow = 0;
		let kidsMid = 0;
		$.each(
			model.kids,
			(idx, age) => {
				if (age <= 3) {
					kidsFree += 1;
				} else if (age <= 6) {
					kidsLow += 1;
				} else {
					kidsMid += 1;
				}
			}
		);
		return {
			full: {
				rate: 1,
				amount: adults
			},
			mid: {
				rate: 0.8,
				amount: kidsMid
			},
			low: {
				rate: 0.75,
				amount: kidsLow
			},
			lowest: {
				rate: 0,
				amount: kidsFree
			},
			special: {
				rate: 0,
				amount: 0
			}
		};
	}
};

$(() => {
	$('#kids').val(''); // Reset kids

	// create calendar
	$('<span class=year/>')
		.text(year)
		.toggleClass('old', year < new Date().getUTCFullYear())
		.appendTo('#datepicker');

	for (let i = numeroMeseIniziale; i <= numeroMeseFinale; i += 1) {
		$(calendar(i, year)).appendTo('#datepicker');
	}
	$days = $('#datepicker span');

	$('input').keyup(process).click(process); // Respond to kb input

	$('#form button.people').click(function () {
		// Less+more buttons
		const $number = $(this).siblings('input');

		let currentValue = parseInt($number.val(), 10);
		if (isNaN(currentValue)) {
			currentValue = 0;
		}

		if ($(this).attr('class').search('more') >= 0) {
			$number.val(currentValue + 1);
		} else {
			$number.val(currentValue ? currentValue - 1 : currentValue);
		}

		process();
	});

	$('#form button.nights').click(function () {
		// Less+more buttons
		if ($selected.length === 0) {
			return;
		}

		const $elem = $selected.last();
		let pos = $days.index($elem);
		if ($(this).is('.more')) {
			pos += 1;
		} else if ($selected.length > 2) {
			pos -= 1;
		}

		clickInPlace = true;
		$days.eq(pos).mouseenter();
	});

	$('#form .kidsadd button').click(function () {
		const newKid = $(this).data('val');
		if (newKid) {
			$('#kids').val(`${$('#kids').val()} ${newKid}`);
			const $label = $(this).find('span');
			let val = $label.text() || 0;
			if (val === '•') {
				val = 1;
			}
			val = parseInt(val, 10) + 1;
			if (val === 1) {
				val = '•';
			}
			$label.text(val);
		} else {
			$('#kids').val('');
			$('.kidsadd span').text('');
		}

		process();
	});

	let onDocumentTouchMove;
	$('#datepicker').bind('touchmove touchstart', event => {
		event.preventDefault();
	}).bind('touchstart touchmove', event => {
		event = event.originalEvent;
		onDocumentTouchMove = {};
		onDocumentTouchMove.x = event.changedTouches[event.changedTouches.length - 1].clientX;
		onDocumentTouchMove.y = event.changedTouches[event.changedTouches.length - 1].clientY;
	}).bind('touchstart', event => {
		event.preventDefault();
		const elem = document.elementFromPoint(onDocumentTouchMove.x, onDocumentTouchMove.y);
		$(elem).trigger('mousedown');
	}).bind('touchmove', event => {
		event.preventDefault();
		const elem = document.elementFromPoint(onDocumentTouchMove.x, onDocumentTouchMove.y);
		$(elem).trigger('mouseenter');
	}).bind('touchend', event => {
		event.preventDefault();
		const elem = document.elementFromPoint(onDocumentTouchMove.x, onDocumentTouchMove.y);
		$(elem).trigger('mouseup');
	});

	$('#datepicker span')
	// .bind('touchmove',function(){return false})
		.click(function () { // Click in place, select 1 night
			clickInPlace = true;
			$(this).next().mouseenter();
		})
		.bind('mousedown', function () {
			mousedown = true;
			$days.removeClass('click selected');
			$(this).addClass('click').trigger('mouseenter');
			return false;
		})
		.bind('mouseenter', function () {
			if (clickInPlace) {
				clickInPlace = false;
			} else if (!mousedown) {
				return;
			}

			$(this).addClass('selected');

			let first;
			let last;
			const thisPosition = $days.index(this);
			const clickPosition = $days.index($('.click'));

			if (thisPosition < clickPosition) {
				first = thisPosition;
				last = clickPosition;
			} else {
				first = clickPosition;
				last = thisPosition;
			}

			// To make them work with :gt and :lt
			first -= 1;
			last += 1;

			$selected = $days.filter(`:lt(${last}):gt(${first})`);
			// $days.

			$days.removeClass('selected');
			$selected.addClass('selected');

			process();
		});
	$('body').bind('mouseup', () => {
		mousedown = false;
	});
	$('button[type=reset]').on('click', () => {
		location.reload();
	});
});

function makeDate(day, month, year) {
	return new Date(year, month - 1, day);
}

const year = 2021;

const rates = [
	// Start dates
	{
		day: makeDate(1, 1, year),
		pension: {
			bb: 35,
			hb: 58,
			fb: 81
		}
	}, {
		day: makeDate(5, 6, year),
		pension: {
			bb: 45,
			hb: 68,
			fb: 91
		}
	}, {
		day: makeDate(26, 6, year),
		pension: {
			bb: 55,
			hb: 78,
			fb: 101
		}
	}, {
		day: makeDate(10, 7, year),
		pension: {
			bb: 65,
			hb: 88,
			fb: 111
		}
	}, {
		day: makeDate(7, 8, year),
		pension: {
			bb: 90,
			hb: 113,
			fb: 136
		}
	}, {
		day: makeDate(21, 8, year),
		pension: {
			bb: 65,
			hb: 88,
			fb: 111
		}
	}, {
		day: makeDate(4, 9, year),
		pension: {
			bb: 40,
			hb: 63,
			fb: 86
		}
	}, {
		day: makeDate(27, 12, year),
		pension: {
			bb: 0,
			hb: 0,
			fb: 0
		}
	} // Filler for loop
];

const cradlePerDay = 8;
let mousedown = false;
let clickInPlace = false;
let $selected = $('');
let $days;
let months;

function textDate(date) {
	months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
	const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
	return `${days[date.getDay()]}, ${date.getDate()} ${months[date.getMonth()]}`;
}

function process() {
	if ($('.selected').length === 0) {
		return;
	} // If it hasn't been set do nothing

	const {firstDay, lastDay} = model.dates;
	const days = Math.round((lastDay - firstDay) / (1000 * 60 * 60 * 24));
	const cradle = $('#cradle:checked').length * cradlePerDay * days;

	const costPerPerson = getCostPerPerson(rates, firstDay, lastDay);

	const total = {};
	for (const costType in costPerPerson) {
		let base = Object.keys(model.people).reduce((base, personType) => {
			return base + (costPerPerson[costType] *
							model.people[personType].rate *
							model.people[personType].amount);
		}, 0);

		// Add cradle
		base += cradle;

		const rate = total[costType] = {};

		rate.full = base;

		if (model.isDiscountedRoom) {
			base = rate.grounded = base * 0.9;
		}

		if (model.arbitraryDiscount) {
			base = rate.arbitrary = base * ((100 - model.arbitraryDiscount) / 100);
		}

		if (model.isEarlyBird) {
			base = rate.earlybird = base * 0.95;
		}

		rate.final = base;

		Object.keys(rate).forEach(fieldName => {
			rate[fieldName] = Math.round(rate[fieldName]);
		});

		rate.discount = rate.full - rate.final;
		rate.deposit = Math.round(rate.final * 0.30);

		Object.keys(rate).forEach(fieldName => {
			$(`#output .${costType} .${fieldName}`).text(rate[fieldName]);
		});
	}
	$('#form .date0.extended').text(textDate(firstDay));
	$('#form .date1.extended').text(textDate(lastDay));

	$('#output').toggleClass('has-room-discount', model.isDiscountedRoom);
	$('#output').toggleClass('has-arbitrary-discount', Boolean(model.arbitraryDiscount));
	$('#output').toggleClass('has-early-bird-discount', model.isEarlyBird);

	$('#output .selected').removeClass('selected');

	let finalSelector;
	if (model.isEarlyBird) {
		finalSelector = 'earlybird';
	} else if (model.arbitraryDiscount) {
		finalSelector = 'arbitrary';
	} else if (model.isDiscountedRoom) {
		finalSelector = 'grounded';
	} else {
		finalSelector = 'full';
	}
	$(`#output .${finalSelector}`).addClass('selected');
	$('th.arbitrary').text(`-${model.arbitraryDiscount}%`);

	$('#nights').val((lastDay - firstDay) / 3600000 / 24);
	preventivo(firstDay, lastDay, total);
}

function getCostPerPerson(rates, firstDay, lastDay) {
	const period = {
		firstDay: null,
		lastDay: null,
		days: null
	};
	const cost = {};
	for (const costType in rates[0].pension) {
		cost[costType] = 0;
	}
	// Reach first period
	let periodNumber = 0;
	while (firstDay >= rates[periodNumber + 1].day) {
		periodNumber += 1;
	}
	for (period.firstDay = firstDay; period.lastDay < lastDay; periodNumber += 1) {
		if (typeof rates[periodNumber + 1] === 'undefined' || lastDay < rates[periodNumber + 1].day) {
			period.lastDay = lastDay;
		} else {
			period.lastDay = rates[periodNumber + 1].day;
		}

		period.days = Math.round((period.lastDay - period.firstDay) / (1000 * 60 * 60 * 24));
		for (const costType in cost) {
			cost[costType] += rates[periodNumber].pension[costType] * period.days;
		}
		// Console.log(period.days+" days from",period.firstDay,"to:",period.lastDay);
		period.firstDay = period.lastDay; // Next period's firstDay = this period's lastDay
	}
	return cost;
}

const numeroMeseIniziale = 6;
const numeroMeseFinale = 10;

function calendar(month, year) {
	const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
	const weekdays = ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'];
	const days = daysInMonth(month - 1, year);
	const monthLine = $('<div/>');
	let day;
	let weekday;
	let date;
	let box;
	let line;

	for (line = 0; line < 8; line += 1) {
		if (line === 0) {
			// Month name
			$('<div/>')
				.addClass('month')
				.text(months[month - 1])
				.appendTo(monthLine);

			// Days
			for (day = 0; day < days; day += 1) {
				weekday = getWeekDay(day, month, year);
				date = new Date(year, month - 1, day + 1);
				box = $('<span />')
					.addClass(weekdays[weekday])
					.data('date', date)
					.text(day + 1)
					.appendTo(monthLine);
				if (differenceWithToday(date) < 0) {
					box.addClass('past');
				}
			}
		}
	}
	return monthLine;
}

function getArticlePart(number) {
	switch (number) {
		case 1:
		case 8:
		case 11:
			return `l'${number}`;
		default:
			return ` ${number}`;
	}
}
function differenceWithToday(selected) {
	return Math.ceil((selected - (new Date())) / 3600000 / 24);
}

function getWeekDay(day, month, year) {
	return (new Date(year, month - 1, day + 2)).getUTCDay();
}

function daysInMonth(iMonth, iYear) {
	return 32 - new Date(iYear, iMonth, 32).getDate();
}

function preventivo(firstDay, lastDay, total) {
	let t = '<p>Gentile sig. <br/>il costo del soggiorno per ';

	if (model.people.full.amount === 1) {
		t += 'un adulto ';
	} else {
		t += `${model.people.full.amount} adulti `;
	}

	if (model.kids.length > 0) {
		t += 'e ';
		if (model.kids.length === 1) {
			// Todo check gender
			t += 'un bambino ';
		} else {
			t += `${model.kids.length} bambini `;
		}
		t += 'di ';
		for (let i = 0; i < model.kids.length; i += 1) {
			t += model.kids[i];
			if (i < model.kids.length - 2) {
				t += ', ';
			} else if (model.kids.length > 1 && i === model.kids.length - 2) {
				t += ' e ';
			}
		}
		if (model.kids[model.kids.length - 1] === 1) {
			t += ' anno ';
		} else {
			t += ' anni ';
		}
	}
	t += ' tra ';
	switch (firstDay.getDate()) {
		case 1:
		case 8:
		case 11:
			t += 'l\'';
			break;
		default:
			t += 'il ';
	}
	t += firstDay.getDate();
	if (firstDay.getMonth() !== lastDay.getMonth()) {
		t += ` ${months[firstDay.getMonth()]}`;
	}

	switch (lastDay.getDate()) {
		case 1:
		case 8:
		case 11:
			t += ' e l\'';
			break;
		default:
			t += ' ed il ';
	}
	t += `${lastDay.getDate()} ${months[lastDay.getMonth()]}`;
	t += ' mattina è';

	const pensions = {};
	if ($('#bbcheck:checked').length > 0) {
		pensions['inclusa colazione'] = total.bb;
	}
	if ($('#hbcheck:checked').length > 0) {
		pensions['in mezza pensione'] = total.hb;
	}
	if ($('#fbcheck:checked').length > 0) {
		pensions['in pensione completa'] = total.fb;
	}
	t += ':<ul>';

	for (const pension in pensions) {
		t += '<li>';
		t += pensions[pension].final;
		t += ` € ${pension}`;
		if (pensions[pension].discount) {
			t += ` (invece di <s>${pensions[pension].full}€</s>)`;
		}
		t += '</li>';
	}
	t += '</ul>';

	t += '<b>Tipo camera:</b> ';
	switch (model.roomType) {
		case 'double':
			t += 'camera doppia con balcone vista mare (una stanza più un bagno)';
			break;
		case 'family':
			t += 'camera quadrupla con balcone vista mare (2 stanze comunicanti con bagno)';
			break;
		case 'ground':
			t += 'camera quadrupla al piano terra (2 stanze comunicanti con bagno)';
			break;
	}

	if ($('#cradle:checked').length > 0) {
		t += ' con una culla';
	}
	t += '</p><p>';
	if (model.kids.some(age => age <= 3)) {
		if ($('#cradle:checked').length > 0) {
			t += 'I bambini fino a 3 anni pagano solo il costo della culla (8 € al giorno, incluso nel preventivo).';
		} else {
			t += 'I bambini fino a 3 anni soggiornano gratuitamente nel letto dei genitori, altrimenti il costo della culla è di 8 € al giorno.';
		}
		if ($('#hbcheck:checked, #fbcheck:checked').length > 0) {
			t += ' Eventuali pasti di questi non sono inclusi.';
		}
		t += '</p><p>';
	}
	const discounts = [];
	if (model.kids.length > 0) {
		discounts.push('lo sconto per i bambini fino a 15 anni');
	}
	if (model.isEarlyBird) {
		discounts.push('lo sconto del 5% per la prenotazione con largo anticipo');
	}
	if (model.isDiscountedRoom) {
		discounts.push('lo sconto del 10% per la camera al piano terra');
	}
	if (model.arbitraryDiscount) {
		discounts.push(`uno sconto aggiuntivo del${getArticlePart(model.arbitraryDiscount)}% solo per lei`);
	}
	if (discounts.length > 0) {
		const oneSelectedPension = $('#output input:checked').length === 1;
		if (oneSelectedPension) {
			t += 'Il prezzo include ';
		} else {
			t += 'I prezzi includono ';
		}
		t += discounts.length === 1 ? `${discounts[0]}.</p>` : `<ul><li>${discounts.join('</li><li>')}.</li></ul>`;
	}
	t += '<p>Per conoscere la nostra tariffazione in dettaglio può visitare la pagina: <a href=\'https://hotelteti.com/tariffazione/\'>https://hotelteti.com/tariffazione/</a></p>';
	t += `
		<p><b>Servizi in camera</b></p>
		<ul>
			<li>Aria condizionata autonoma</li>
			<li>Wi-Fi gratuito</li>
			<li>TV LED 30 pollici con ricezione satellitare</li>
			<li>Cassaforte</li>
			<li>Asciuga capelli</li>
			<li>Frigo</li>
			<li>Box doccia</li>
		</ul>
		<p><b>Servizi dell'hotel</b></p>
		<ul>
			<li>Parcheggio riservato all'interno della struttura</li>
			<li>Un’ampia terrazza al primo piano fronte mare riservata a tutti gli ospiti dell’hotel</li>
		</ul>
	`;

	if ($('#hbcheck:checked, #fbcheck:checked').length > 0) {
		t += `
			<p><b>Ristorante</b></p>
			<p>Ogni pasto comprende un primo, un secondo ed un contorno, sono inclusi acqua e vino della casa. I piatti vengono scelti da una carte giornaliera dove sono presenti pietanza a base di carne, pesce e verdure, serviti al tavolo (non è un buffet).</p>
		`;
	}

	t += '<p><b>Passi per la conferma</b><br/>';
	t += '<ol>';
	t += '<li>Ci chiami o risponda a quest\'email specificando il suo nome completo, bloccheremo temporaneamente la camera (massimo 3 giorni)</li>';
	t += '<li>Versi ';
	if (total.hb.final < 300) {
		t += 'il totale';
	} else {
		t += `la caparra di ${Math.ceil(total.hb.deposit / 20) * 20} €`;
	}
	t += ' tramite bonifico</li>';
	t += '<li>Ci chiami o risponda a quest\'email per segnalare l\'avvenuto movimento</li>';
	t += '</ol>';
	t += 'A quel punto Le manderemo un\'email di conferma dell\'avvenuta prenotazione.</p>';
	t += '<p><b>Dati per il bonifico</b><br/>';
	t += 'Intestazione: Brico di Brigante A. e Congedi C. s.n.c.<br/>';
	t += 'IBAN: IT10 S052 6280 130C C047 1023 527<br/>';
	t += 'BIC: BPPUIT33<br/>';
	t += 'Banca Popolare Pugliese - via Messapica – Ugento<br/>';
	t += 'Causale: Caparra [suo nome completo] [data arrivo] [data partenza]</p>';
	t += '<p>In caso di annullamento del soggiorno per forze maggiori, la caparra vi sarà restituita per intero.</p>';
	// Todo: fill dates
	t += '<p>Cordiali saluti, <br/>Antonio Brigante</p>';

	$('#preventivo').html(t);
}

navigator.serviceWorker.register('service-worker.js');

let idleTimer;
window.addEventListener('visibilitychange', () => {
	if (document.hidden) {
		idleTimer = setTimeout(() => location.reload(), 1000 * 60 * 10);
	} else {
		clearTimeout(idleTimer);
	}
});
